import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { useAnimation } from "framer-motion";
import { FaPhone } from "react-icons/fa";

import { send } from "emailjs-com";

import "./Contact.scss";

const Contact = () => {
  const [sender_name, set_sender_name] = useState("");
  const [sender_email, set_sender_email] = useState("");
  const [sender_phone, set_sender_phone] = useState("");
  const [message, set_message] = useState("");

  const handleName = (e) => {
    set_sender_name(e.target.value);
  };

  const handleEmail = (e) => {
    set_sender_email(e.target.value);
  };

  const handlePhone = (e) => {
    set_sender_phone(e.target.value);
  };

  const handleMessage = (e) => {
    set_message(e.target.value);
  };

  const sendMail = (e) => {
    e.preventDefault();
    send(
      "service_2s4zkgi",
      "template_9rtzpqf",
      { sender_name, sender_email, sender_phone, message },
      "RKZIhi3DSThZonsQg"
    )
      .then((response) => {
        console.log(
          "Message sent successfully",
          response.status,
          response.text
        );
      })
      .catch((err) => {
        console.log("Failed to send message", err);
      });
    set_sender_name("");
    set_sender_email("");
    set_sender_email("");
    set_message("");
  };

  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  const animationHeader = useAnimation();
  const animationTextInput1 = useAnimation();
  const animationTextInput2 = useAnimation();
  const animationTextInput3 = useAnimation();
  const animationTextArea = useAnimation();
  const animationButton = useAnimation();

  useEffect(() => {
    if (inView) {
      animationHeader.start({
        x: 0,
        opacity: 1,
        transition: {
          type: "spring",
          duration: 0.5,
          bounce: 0.23,
          delay: 0.1,
        },
      });
      animationTextInput1.start({
        x: 0,
        opacity: 1,
        transition: {
          type: "spring",
          duration: 0.5,
          bounce: 0.23,
          delay: 0.3,
        },
      });
      animationTextInput2.start({
        x: 0,
        opacity: 1,
        transition: {
          type: "spring",
          duration: 0.5,
          bounce: 0.23,
          delay: 0.4,
        },
      });
      animationTextInput3.start({
        x: 0,
        opacity: 1,
        transition: {
          type: "spring",
          duration: 0.5,
          bounce: 0.23,
          delay: 0.55,
        },
      });
      animationTextArea.start({
        x: 0,
        opacity: 1,
        transition: {
          type: "spring",
          duration: 0.5,
          bounce: 0.23,
          delay: 0.7,
        },
      });
      animationButton.start({
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.5,
          delay: 0.85,
        },
      });
    }

    if (!inView) {
      animationHeader.start({
        x: "100vw",
        opacity: 0,
        transition: {
          type: "spring",
          duration: 2,
        },
      });
      animationTextInput1.start({
        x: "100vw",
        opacity: 0,
        transition: {
          type: "spring",
          duration: 2,
        },
      });
      animationTextInput2.start({
        x: "100vw",
        opacity: 0,
        transition: {
          type: "spring",
          duration: 2,
        },
      });
      animationTextInput3.start({
        x: "100vw",
        opacity: 0,
        transition: {
          type: "spring",
          duration: 2,
        },
      });
      animationTextArea.start({
        x: "100vw",
        opacity: 0,
        transition: {
          type: "spring",
          duration: 1.3,
          bounce: 0.23,
        },
      });
      animationButton.start({
        y: "-2vw",
        opacity: 0,
        transition: {
          duration: 0.3,
        },
      });
    }
  }, [
    inView,
    animationHeader,
    animationTextInput1,
    animationTextInput2,
    animationTextInput3,
    animationTextArea,
    animationButton,
  ]);

  return (
    <div ref={ref} className="app__contact-container" id="contact">
      <div className="app__contact-text">
        <motion.div animate={animationHeader}>
          <h1>CONTACT</h1>
          <a href="tel://+6267272062">
            <FaPhone color="white" size="18px" />
            &nbsp; 626-727-2062{" "}
          </a>
        </motion.div>
      </div>

      <div className="app__contact-form">
        <form onSubmit={sendMail}>
          <motion.div animate={animationTextInput1}>
            <div className="app__contact-form-input-row">
              <label className="input">
                <input
                  className="input__field"
                  name="sender_name"
                  value={sender_name}
                  onChange={handleName}
                  type="text"
                  required
                  placeholder=" "
                />
                <span className="input__label">NAME</span>
              </label>
            </div>
          </motion.div>
          <motion.div animate={animationTextInput2}>
            <div className="app__contact-form-input-row">
              <label className="input">
                <input
                  className="input__field"
                  name="sender_email"
                  value={sender_email}
                  onChange={handleEmail}
                  type="text"
                  required
                  placeholder=" "
                />
                <span className="input__label">EMAIL</span>
              </label>
            </div>
          </motion.div>
          <motion.div animate={animationTextInput3}>
            <div className="app__contact-form-input-row">
              <label className="input">
                <input
                  className="input__field"
                  name="sender_phone"
                  value={sender_phone}
                  onChange={handlePhone}
                  type="text"
                  required
                  placeholder=" "
                />
                <span className="input__label">PHONE</span>
              </label>
            </div>
          </motion.div>

          <motion.div animate={animationTextArea}>
            <div className="app__contact-form-input-row">
              <label className="textarea">
                <textarea
                  className="textarea__field"
                  name="message"
                  value={message}
                  onChange={handleMessage}
                  required
                  placeholder=" "
                />
                <span className="textarea__label">MESSAGE</span>
              </label>
            </div>
          </motion.div>
          <div style={{ textAlign: "center" }}>
            <motion.div animate={animationButton}>
              <button type="submit">Send</button>
            </motion.div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
