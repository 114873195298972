import React from "react";

import "./Footer.scss";
import { images } from "../../constants";

import { FaPhone, FaHome, FaAngleUp } from "react-icons/fa";

const Footer = () => {
  const handleScroll = () => {
    document.documentElement.scrollTo(0, 0);
  };

  return (
    <div className="footer-container">
      <div className="footer-content">
        <div className="left-content">
          <img src={images.Logo} alt="412 Autobody Logo" />
          <p>Copyright © 2022 &nbsp;&nbsp;•&nbsp;&nbsp; 412 Autobody</p>
        </div>
        <div className="middle-content">
          <a href="tel://+6267272062">
            <FaPhone color="white" size="15px" />
            &nbsp; 626-727-2062{" "}
          </a>
          <p>
            {" "}
            <FaHome color="white" size="15px" />
            &nbsp; 7600 N Clybourn Ave, Sun Valley, CA 91352{" "}
          </p>
        </div>
        <div className="right-content">
          <FaAngleUp
            color="white"
            size="25px"
            onClick={handleScroll}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
