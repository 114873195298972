import React from "react";
import { useHistory } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";
import { useEffect } from "react";

import "./AboutInfo.scss";
import { images } from "../../constants";

const AboutInfo = () => {
  const history = useHistory();

  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  const animationHeader1 = useAnimation();
  const animationHeader2 = useAnimation();
  const animationSkill1 = useAnimation();
  const animationSkill2 = useAnimation();
  const animationSkill3 = useAnimation();
  const animationImage = useAnimation();
  const animationButton = useAnimation();

  useEffect(() => {
    if (inView) {
      animationHeader1.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 0.8,
          bounce: 0.2,
          delay: 0.1,
        },
      });
      animationHeader2.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 0.8,
          bounce: 0.2,
          delay: 0.15,
        },
      });
      animationSkill1.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 0.8,
          bounce: 0.2,
          delay: 0.3,
        },
      });
      animationSkill2.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 0.8,
          bounce: 0.2,
          delay: 0.45,
        },
      });
      animationSkill3.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 0.8,
          bounce: 0.2,
          delay: 0.6,
        },
      });
      animationImage.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 0.8,
          bounce: 0.2,
          delay: 0.35,
        },
      });
      animationButton.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 0.8,
          bounce: 0.2,
          delay: 0.7,
        },
      });
    }

    if (!inView) {
      animationHeader1.start({
        x: "-100vw",
        transition: {
          type: "spring",
          duration: 1,
        },
      });
      animationHeader2.start({
        x: "100vw",
        transition: {
          type: "spring",
          duration: 1,
        },
      });
      animationSkill1.start({
        x: "100vw",
        transition: {
          type: "spring",
          duration: 1,
        },
      });
      animationSkill2.start({
        x: "100vw",
        transition: {
          type: "spring",
          duration: 1,
        },
      });
      animationSkill3.start({
        x: "100vw",
        transition: {
          type: "spring",
          duration: 1,
        },
      });
      animationImage.start({
        x: "-100vw",
        transition: {
          type: "spring",
          duration: 1,
        },
      });
      animationButton.start({
        x: "100vw",
        transition: {
          type: "spring",
          duration: 1,
        },
      });
    }
  }, [
    inView,
    animationHeader1,
    animationHeader2,
    animationSkill1,
    animationSkill2,
    animationSkill3,
    animationImage,
    animationButton,
  ]);

  return (
    <div className="AboutInfo-container" ref={ref}>
      <motion.div animate={animationHeader1}>
        <h1> ABOUT US </h1>
      </motion.div>
      <div className="AboutInfo-content">
        <div className="AboutInfo-content-right">
          <motion.div animate={animationImage}>
            <img alt="placeholder" src={images.placeholder2} loading="lazy" />
          </motion.div>
        </div>
        <div className="AboutInfo-content-left">
          <motion.div animate={animationHeader2}>
            <h2>Who we are.</h2>
          </motion.div>
          <motion.div animate={animationSkill1}>
            <p>
              {" "}
              Located in Burbank, CA, we specialize in collision repairs and
              paint services on european and exotic vehicles. Founded and
              staffed by auto enthusiasts, we have nearly two decades of
              experience performing repairs on both domestic and foreign
              vehicles. We take pride in the quality of our work and highly
              value our relationships with our customers.
            </p>
          </motion.div>
          <motion.div animate={animationSkill2}>
            <p>
              {" "}
              We are located at{" "}
              <b> 7600 N Clybourn Ave, Sun Valley, CA 91352</b>{" "}
            </p>
          </motion.div>
          <motion.div animate={animationSkill3}>
            <p>
              {" "}
              We are open from <b> 9 AM to 5 PM on weekdays</b>.
            </p>
            <p>
              {" "}
              Need to get a message to us? Just call <b>626-727-2062</b> or
              click the button below.
            </p>
          </motion.div>
          <motion.div animate={animationButton}>
            <button onClick={() => history.push("/contact")}>CONTACT US</button>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default AboutInfo;
