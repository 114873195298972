import React, { useState } from "react";
import { AiFillInstagram, AiFillYoutube } from "react-icons/ai";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import "./Navbar.scss";

import { images } from "../../constants";

const Navbar = () => {
  const history = useHistory();
  const [toggle, setToggle] = useState(false);

  const handleYoutube = () => {
    window.open("https://www.youtube.com/c/412Motorsport");
  };

  const handleInstagram = () => {
    window.open("https://www.instagram.com/412motorsport/?hl=en");
  };

  return (
    <nav className="app__navbar">
      <div className="app__navbar-namelogo">
        <img
          alt="412 Autobody Logo"
          src={images.Logo}
          onClick={() => history.push("/")}
        />
      </div>
      <ul className="app__navbar-links">
        <li>
          <span className="container" onClick={() => history.push("/")}>
            <div className="text" data-text="Home">
              Home
            </div>
          </span>
        </li>
        <li>
          <span className="container" onClick={() => history.push("/help")}>
            <div className="text" data-text="Help">
              Help
            </div>
          </span>
        </li>
        <li>
          <span className="container" onClick={() => history.push("/gallery")}>
            <div className="text" data-text="Gallery">
              Gallery
            </div>
          </span>
        </li>
        <li>
          <span className="container" onClick={() => history.push("/about")}>
            <div className="text" data-text="About">
              About
            </div>
          </span>
        </li>
        <li>
          <span className="container" onClick={() => history.push("/contact")}>
            <div className="text" data-text="Contact">
              Contact
            </div>
          </span>
        </li>
      </ul>

      <div className="socialIcon-container">
        <div
          className="socialIcon"
          onClick={handleYoutube}
          href="https://Youtube.com"
        >
          <AiFillYoutube color="white" size="2.3rem" />
        </div>
        <div
          className="socialIcon"
          onClick={handleInstagram}
          href="https://Instagram.com"
        >
          <AiFillInstagram color="white" size="2.3rem" />
        </div>
      </div>

      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.3, ease: "easeOut" }}
          >
            <HiX color="white" onClick={() => setToggle(false)} />
            <ul className="app__navbar-links">
              <li className="app__flex p-text">
                <button
                  onClick={() => {
                    history.push("/");
                    setToggle(false);
                  }}
                >
                  Home
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    history.push("/help");
                    setToggle(false);
                  }}
                >
                  Help
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    history.push("/gallery");
                    setToggle(false);
                  }}
                >
                  Gallery
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    history.push("/about");
                    setToggle(false);
                  }}
                >
                  About
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    history.push("/contact");
                    setToggle(false);
                  }}
                >
                  Contact
                </button>
              </li>
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
