//Credit to upklyak for images
import Logo from "../assets/412Logo.png";
import BannerIMG from "../assets/BannerIMG.png";
import R34 from "../assets/R34.png";
import M5 from "../assets/M5.png";
import BMWLogo from "../assets/BMWLogo.png";
import MercedesLogo from "../assets/MercedesLogo.png";
import AudiLogo from "../assets/AudiLogo.png";
import LamborghiniLogo from "../assets/LamborghiniLogo.png";
import PorscheLogo from "../assets/PorscheLogo.png";
import FerrariLogo from "../assets/FerrariLogo.png";
import BentleyLogo from "../assets/BentleyLogo.png";
import MclarenLogo from "../assets/MclarenLogo.png";
import Mac from "../assets/720S.png";
import placeholder from "../assets/placeholder.jpg";
import placeholder2 from "../assets/front1.png";
import gallery1 from "../assets/gallery1.jpeg";
import gallery2 from "../assets/gallery2.jpeg";
import gallery3 from "../assets/gallery3.jpeg";
import gallery4 from "../assets/gallery4.jpeg";
import gallery5 from "../assets/gallery5.jpeg";
import gallery6 from "../assets/gallery6.jpg";
import gallery7 from "../assets/gallery7.jpg";
import gallery8 from "../assets/gallery8.jpg";
import gallery9 from "../assets/gallery9.jpg";
import gallery10 from "../assets/gallery10.jpg";
import gallery11 from "../assets/gallery11.jpg";
import gallery12 from "../assets/gallery12.JPG";
import gallery13 from "../assets/gallery13.jpg";
import gallery14 from "../assets/gallery14.jpg";
import gallery15 from "../assets/gallery15.jpg";
import gallery16 from "../assets/gallery16.JPG";

const imageExport = {
  Logo,
  BannerIMG,
  R34,
  M5,
  Mac,
  BMWLogo,
  MercedesLogo,
  AudiLogo,
  LamborghiniLogo,
  PorscheLogo,
  FerrariLogo,
  BentleyLogo,
  MclarenLogo,
  placeholder,
  placeholder2,
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery8,
  gallery9,
  gallery10,
  gallery11,
  gallery12,
  gallery13,
  gallery14,
  gallery15,
  gallery16,
};

export default imageExport;
