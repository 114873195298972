import React from "react";

import Contact from "../components/Contact/Contact";

const ContactPage = () => {
  return (
    <div style={{ paddingTop: "8rem", minHeight: "48vw" }}>
      <Contact />
    </div>
  );
};

export default ContactPage;
