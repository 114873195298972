import { Router } from "react-router";
import { Route } from "react-router-dom";

import "./App.css";
import { Navbar } from "./components";
import HomePage from "./Pages/HomePage";
import HelpPage from "./Pages/HelpPage";
import GalleryPage from "./Pages/GalleryPage";
import AboutPage from "./Pages/AboutPage";
import ContactPage from "./Pages/ContactPage";
import Footer from "./components/Footer/Footer";
import { useLocation } from "react-router-dom";
import { useLayoutEffect } from "react";

function App() {
  var createHistory = require("history").createBrowserHistory;
  const history = createHistory();

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  return (
    <Router history={history}>
      <div className="app">
        <Navbar />
        <Wrapper>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/help" component={HelpPage} />
          <Route exact path="/gallery" component={GalleryPage} />
          <Route exact path="/about" component={AboutPage} />
          <Route exact path="/contact" component={ContactPage} />
        </Wrapper>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
