import React from "react";
import { useHistory } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";
import { useEffect } from "react";

import { images } from "../../constants";
import "./Banner.scss";

const Banner = () => {
  const history = useHistory();

  const { ref, inView } = useInView({
    threshold: 0.000001,
  });

  const animationImage = useAnimation();
  const animationHeader = useAnimation();
  const animationText1 = useAnimation();
  const animationText2 = useAnimation();
  const animationButton = useAnimation();
  const animationArrow = useAnimation();

  useEffect(() => {
    if (inView) {
      animationImage.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 0.8,
          bounce: 0.2,
          delay: 0.2,
        },
      });
      animationHeader.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 0.8,
          bounce: 0.2,
          delay: 0.3,
        },
      });
      animationText1.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 0.8,
          bounce: 0.2,
          delay: 0.4,
        },
      });
      animationText2.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 0.8,
          bounce: 0.2,
          delay: 0.5,
        },
      });
      animationButton.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 0.8,
          bounce: 0.2,
          delay: 0.6,
        },
      });
      animationArrow.start({
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.25,
          delay: 0.9,
        },
      });
    }
    if (!inView) {
      animationImage.start({
        x: "-100vw",
        transition: {
          type: "spring",
          duration: 1,
        },
      });
      animationHeader.start({
        x: "100vw",
        transition: {
          type: "spring",
          duration: 1,
        },
      });
      animationText1.start({
        x: "100vw",
        transition: {
          type: "spring",
          duration: 1,
        },
      });
      animationText2.start({
        x: "100vw",
        transition: {
          type: "spring",
          duration: 1,
        },
      });
      animationButton.start({
        x: "100vw",
        transition: {
          type: "spring",
          duration: 1,
        },
      });
      animationArrow.start({
        y: "-3vw",
        opacity: 0,
        transition: {
          duration: 0.3,
          delay: 0,
        },
      });
    }
  }, [
    inView,
    animationImage,
    animationHeader,
    animationText1,
    animationText2,
    animationButton,
    animationArrow,
  ]);

  return (
    <>
      <div className="banner-container" id="Banner">
        <div className="banner-column-left">
          <motion.div animate={animationImage}>
            <img
              loading="lazy"
              src={images.BannerIMG}
              alt="412 Autobody shop"
            />
          </motion.div>
        </div>
        <div ref={ref} className="banner-column-right">
          <motion.div animate={animationHeader}>
            <h1>
              Get your vehicle repaired with people who know it inside and out.
            </h1>
          </motion.div>
          <motion.div animate={animationText1}>
            <h2>
              We have nearly two decades of experience ensuring high quality
              collision repairs and paint work on foreign & domestic vehicles.
              We work with all insurance providers.
            </h2>
          </motion.div>
          <motion.div animate={animationText2}>
            <p>Located in Burbank, CA</p>
          </motion.div>
          <motion.div animate={animationButton}>
            <button onClick={() => history.push("/contact")}>
              GET A FREE ESTIMATE
            </button>
          </motion.div>
        </div>
      </div>
      <div className="arrow-container-main">
        <motion.div animate={animationArrow}>
          <a
            href="#Carousel"
            className="arrow-container"
            alt="Go to next section"
          >
            <div className="arrow"></div>
            <div className="arrow"></div>
            <div className="arrow"></div>
          </a>
        </motion.div>
      </div>
    </>
  );
};

export default Banner;
