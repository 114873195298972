import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useInView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";
import { useEffect } from "react";

import { images } from "../../constants";

import "./Carousel.scss";

const responsive = {
  0: { items: 1 },
  568: { items: 3 },
  1300: { items: 4 },
  1700: { items: 5 },
};

const items = [
  <div className="item">
    <img style={{ maxHeight: "120px" }} src={images.BMWLogo} alt="BMW Logo" />
  </div>,
  <div className="item">
    <img
      style={{ maxHeight: "120px" }}
      src={images.MercedesLogo}
      alt="Mercedes Logo"
    />
  </div>,
  <div className="item">
    <img
      style={{ maxHeight: "120px" }}
      src={images.LamborghiniLogo}
      alt="Lamborghini Logo"
    />
  </div>,
  <div className="item">
    <img
      style={{ maxHeight: "120px" }}
      src={images.FerrariLogo}
      alt="Ferrari Logo"
    />
  </div>,
  <div className="item">
    <img
      style={{ maxHeight: "120px" }}
      src={images.MclarenLogo}
      alt="McLaren Logo"
    />
  </div>,
  <div className="item">
    <img
      style={{ maxHeight: "120px" }}
      src={images.BentleyLogo}
      alt="BMW Logo"
    />
  </div>,
  <div className="item">
    <img
      style={{ maxHeight: "120px" }}
      src={images.PorscheLogo}
      alt="Porsche Logo"
    />
  </div>,
  <div className="item">
    <img style={{ maxHeight: "120px" }} src={images.AudiLogo} alt="BMW Logo" />
  </div>,
];

const Carousel = () => {
  const { ref, inView } = useInView({
    threshold: 0.3,
  });

  const animationHeader1 = useAnimation();
  const animationHeader2 = useAnimation();
  const animationSkill1 = useAnimation();
  const animationSkill2 = useAnimation();
  const animationImage = useAnimation();

  useEffect(() => {
    if (inView) {
      animationHeader1.start({
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.6,
          delay: 0.1,
        },
      });
      animationHeader2.start({
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.5,
          delay: 0.3,
        },
      });
      animationSkill1.start({
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.4,
          delay: 0.5,
        },
      });
      animationSkill2.start({
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.4,
          delay: 0.5,
        },
      });
      animationImage.start({
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.4,
          delay: 0.8,
        },
      });
    }
    if (!inView) {
      animationHeader1.start({
        y: "-3vw",
        opacity: 0,
        transition: {
          duration: 0.5,
          delay: 0.6,
        },
      });
      animationHeader2.start({
        y: "-3vw",
        opacity: 0,
        transition: {
          duration: 0.8,
          delay: 0.5,
        },
      });
      animationSkill1.start({
        y: "3vw",
        opacity: 0,
        transition: {
          duration: 1,
          delay: 0.4,
        },
      });
      animationSkill2.start({
        y: "-3vw",
        opacity: 0,
        transition: {
          duration: 1,
          delay: 0.4,
        },
      });
      animationImage.start({
        y: "-3vw",
        opacity: 0,
        transition: {
          duration: 1,
          delay: 0.2,
        },
      });
    }
  }, [
    inView,
    animationHeader1,
    animationHeader2,
    animationSkill1,
    animationSkill2,
    animationImage,
  ]);

  return (
    <>
      <div ref={ref} className="carousel-container" id="Carousel">
        <h1>
          <motion.div animate={animationHeader1}>WHY CHOOSE US?</motion.div>
        </h1>
        <div className="skills-container">
          <motion.div animate={animationSkill1}>
            <article className="skills__item">
              <div className="skills__item-text">
                <h3>Excellent Customer Service</h3>
                <p>
                  We take pride in the quality of our customer service. We work
                  with all insurance companies to repair your vehicle to its
                  original condition as soon as possible - keeping you in the
                  loop throughout the entire process.
                </p>
              </div>
              <img src={images.Mac} alt="" />
            </article>
          </motion.div>
          <motion.div animate={animationSkill2}>
            <article className="skills__item">
              <div className="skills__item-text">
                <h3>Timely Repairs & Satisfaction Guarantee</h3>
                <p>
                  {" "}
                  We understand the importance of your time and money, as well
                  as the frustrations of having a vehicle temporarily out of
                  service. Started and staffed by auto enthusiasts, we make
                  timely, high quality repairs to get your vehicle back to
                  like-new condition.{" "}
                </p>
              </div>
              <img src={images.M5} alt="" />
            </article>
          </motion.div>
        </div>
        <div />
        <motion.div animate={animationImage}>
          <h2>UNMATCHED EXPERIENCE WITH</h2>
        </motion.div>
        <motion.div animate={animationImage}>
          <AliceCarousel
            autoPlayInterval={1000}
            animationDuration={1500}
            disableDotsControls
            disableButtonsControls
            responsive={responsive}
            autoPlay
            infinite
            autoPlayStrategy="none"
            items={items}
          />
        </motion.div>
      </div>
    </>
  );
};

export default Carousel;
