import React from "react";

import Banner from "../components/Banner/Banner";
import Carousel from "../components/Carousel/Carousel";
import Contact from "../components/Contact/Contact";

const HomePage = () => {
  return (
    <>
      <Banner />
      <Carousel />
      <Contact />
    </>
  );
};

export default HomePage;
