import React from "react";
import { useHistory } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";
import { useEffect } from "react";

import "./HelpInfo.scss";
import { images } from "../../constants";

const HelpInfo = () => {
  const history = useHistory();

  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  const animationHeader1 = useAnimation();
  const animationHeader2 = useAnimation();
  const animationSkill1 = useAnimation();
  const animationSkill2 = useAnimation();
  const animationSkill3 = useAnimation();
  const animationImage = useAnimation();
  const animationButton = useAnimation();

  useEffect(() => {
    if (inView) {
      animationHeader1.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 0.8,
          bounce: 0.2,
          delay: 0.1,
        },
      });
      animationHeader2.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 0.8,
          bounce: 0.2,
          delay: 0.15,
        },
      });
      animationSkill1.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 0.8,
          bounce: 0.2,
          delay: 0.3,
        },
      });
      animationSkill2.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 0.8,
          bounce: 0.2,
          delay: 0.45,
        },
      });
      animationSkill3.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 0.8,
          bounce: 0.2,
          delay: 0.6,
        },
      });
      animationImage.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 0.8,
          bounce: 0.2,
          delay: 0.35,
        },
      });
      animationButton.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 0.8,
          bounce: 0.2,
          delay: 0.7,
        },
      });
    }
    if (!inView) {
      animationHeader1.start({
        x: "100vw",
        transition: {
          type: "spring",
          duration: 1,
        },
      });
      animationHeader2.start({
        x: "-100vw",
        transition: {
          type: "spring",
          duration: 1,
        },
      });
      animationSkill1.start({
        x: "-100vw",
        transition: {
          type: "spring",
          duration: 1,
        },
      });
      animationSkill2.start({
        x: "-100vw",
        transition: {
          type: "spring",
          duration: 1,
        },
      });
      animationSkill3.start({
        x: "-100vw",
        transition: {
          type: "spring",
          duration: 1,
        },
      });
      animationImage.start({
        x: "100vw",
        transition: {
          type: "spring",
          duration: 1,
        },
      });
      animationButton.start({
        x: "-100vw",
        transition: {
          type: "spring",
          duration: 1,
        },
      });
    }
  }, [
    inView,
    animationHeader1,
    animationHeader2,
    animationSkill1,
    animationSkill2,
    animationSkill3,
    animationImage,
    animationButton,
  ]);

  return (
    <div className="HelpInfo-container" ref={ref}>
      <motion.div animate={animationHeader1}>
        <h1> INVOLVED IN AN ACCIDENT? </h1>
      </motion.div>
      <div className="HelpInfo-content">
        <div className="HelpInfo-content-left">
          <motion.div animate={animationHeader2}>
            <h2>Don't worry.</h2>
          </motion.div>
          <motion.div animate={animationSkill1}>
            <p>
              {" "}
              1. Call us at <b>626-727-2062</b> and tell us the details of your
              vehicle and damage as well as any relevant insurance information.
              We work with <b>all</b> insurance providers.
            </p>
          </motion.div>
          <motion.div animate={animationSkill2}>
            <p>
              {" "}
              2. If you are able to safely operate your vehicle, bring it to us
              at <b> 7600 N Clybourn Ave, Sun Valley, CA 91352</b>. Otherwise,
              we can send a tow truck or flat bed to collect your vehicle and
              transport it to our autobody shop.
            </p>
          </motion.div>
          <motion.div animate={animationSkill3}>
            <p>
              {" "}
              3. We will perform an initial check of your vehicle, and within 2
              to 3 days have an adjuster from your insurance company come to
              inspect the vehicle and approve any necessary repairs so we can
              begin restoration.
            </p>
            <p>
              {" "}
              4. We will keep you in the loop throughout the entire process.
              While your vehicle is with us you can be certain that we will
              treat it as if it was our own. Most vehicles that need body work
              are repaired and returned to our customer within 2 to 3 weeks.
            </p>
          </motion.div>
          <motion.div animate={animationButton}>
            <button onClick={() => history.push("/contact")}>
              TALK WITH US
            </button>
          </motion.div>
        </div>
        <div className="HelpInfo-content-right">
          <motion.div animate={animationImage}>
            <img alt="placeholder" src={images.placeholder} loading="lazy" />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default HelpInfo;
