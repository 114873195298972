import React from "react";
import Gallery from "react-photo-gallery";

import "./PhotoGallery.scss";
import { images } from "../../constants";

const PhotoGallery = () => {
  const photos = [
    {
      src: images.R34,
      width: 6,
      height: 4,
    },
    {
      src: images.gallery2,
      width: 3,
      height: 2,
    },
    {
      src: images.gallery1,
      width: 3,
      height: 2,
    },
    {
      src: images.gallery4,
      width: 3,
      height: 2,
    },
    {
      src: images.gallery3,
      width: 6,
      height: 4,
    },
  ];

  const photos2 = [
    {
      src: images.gallery13,
      width: 2,
      height: 3,
    },
    {
      src: images.gallery16,
      width: 2,
      height: 3,
    },
    {
      src: images.gallery3,
      width: 6,
      height: 4,
    },
    {
      src: images.gallery5,
      width: 6,
      height: 4,
    },
    {
      src: images.gallery7,
      width: 4,
      height: 5,
    },
    {
      src: images.gallery6,
      width: 3,
      height: 2,
    },
    {
      src: images.gallery8,
      width: 3,
      height: 2,
    },
    {
      src: images.gallery9,
      width: 2,
      height: 3,
    },
    {
      src: images.gallery14,
      width: 2,
      height: 3,
    },
    {
      src: images.gallery15,
      width: 2,
      height: 3,
    },
    {
      src: images.gallery12,
      width: 2,
      height: 3,
    },
  ];

  return (
    <div className="gallery-container">
      <Gallery photos={photos} />
      <Gallery photos={photos2} direction={"column"} />
    </div>
  );
};

export default PhotoGallery;
